import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`asdasd`}</p>
    </PageDescription>
    <PageDescription mdxType="PageDescription">
      <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    </PageDescription>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <h2>{`Section heading`}</h2>
        <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Add-on repositories, or repos, are components built for a specific product or
experience. The repos are built on top of the core Carbon repo, and enable`}</p>
        </blockquote>
        <p>{`IBMers believe in progress—that application of intelligence, reason and science
can improve business, society and the human condition.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside mdxType="Aside">
          <p>
  <strong>
    Good design is always
    <br />
    good design.
  </strong>
          </p>
          <p>{`What we borrow from our own design history is not a mid-century aesthetic in
stylistic terms, but the modernist attitudes and approach used at the time.`}</p>
        </Aside>
      </Column>
    </Row>
    <h2>{`Small anchor links`}</h2>
    <p>{`Swab barque interloper™ chantey doubloon starboard grog black jack gangway
rutters π.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Header</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Header again</AnchorLink>
    </AnchorLinks>
    <h3>{`Header`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <h3>{`Header again`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <ol>
      <li parentName="ol">{`This is a demonstration of an ordered list item. This list item is
particularly long to demonstrate how the text wraps and aligns with the first
line.`}
        <ol parentName="li">
          <li parentName="ol">{`This is a nested list item, it should wrap in the same way. Typically,
nested list items should be of the same type (ordered, unordered) as their
parent.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Ordered list item`}</li>
      <li parentName="ol">{`Ordered list item`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`This is a demonstration of an unordered list item. This list item is
particularly long to demonstrate how the text wraps and aligns with the first
line.`}
        <ul parentName="li">
          <li parentName="ul">{`This is a nested list item, it should wrap in the same way. Typically,
nested list items should be of the same type (ordered, unordered) as their
parent.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unordered list item`}</li>
      <li parentName="ul">{`Unordered list item`}</li>
    </ul>
    <h2>{`Images`}</h2>
    <h2>{`Videos`}</h2>
    <Video title="Eyes" vimeoId="310583077" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      